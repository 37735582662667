all {
    font-family: 'Mundial' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px !important;
}

.settings {
    position: fixed;
    top: 60px;
    left: 0;
    height: calc(100vh - 60px);
    width: 25vw;
    min-width: 400px; /* Add this line */
    padding: 20px;
    box-sizing: border-box;
    z-index: 1;
    transition: left 0.3s ease-in-out;
    background-color: transparent;
    overflow-x: hidden;
    overflow-y: auto;
}

.settings-content {
    height: 100%;
}

.settings-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;
}

.ant-card-body {
    padding: 0 !important;
    background-color: #FFFFFF !important;
}

.ant-collapse {
    background-color: transparent !important;
}

.ant-collapse-content {
    background-color: #FFFFFF !important;
}

.panel-header {
    color: #4a7298;
    font-size: 17px;
    font-weight: bold;
    margin: 0;
}

.ant-collapse-item {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
}

.ant-collapse-item:last-child {
    border-bottom: none;
}

.ant-collapse-header {
    padding: 12px 16px !important;
}

.ant-collapse-content-box {
    padding: 0 16px 16px !important;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.legend-circle {
    border-radius: 50%;
    margin-right: 8px;
}

.legend-label {
    font-size: 15px;
}

/* Add any additional styles for suggested questions and other components */

/* Adjust any fixed-width elements */
.settings .ant-slider,
.settings .ant-input-number,
.settings .ant-select {
    width: 100% !important;
    max-width: 100%;
}

/* Ensure text wraps and doesn't overflow */
.settings * {
    word-wrap: break-word;
    overflow-wrap: break-word;
}

/* Adjust collapse panels if needed */
.settings .ant-collapse-content-box {
    padding: 10px;
}

/* Adjust any grid layouts */
.settings .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.settings .ant-col {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.settings-button {
    position: fixed;
    top: 20vh;
    left: 0;
    z-index: 2;
    opacity: 0.95;
    transition: left 0.3s ease-in-out;
}

.settings-button.open {
    left: calc(25vw - 30px);
    min-left: 370px;
}

.settings.open {
    left: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media screen and (max-width: 300px) {
    .setting.open {
        min-width: 300px; /* Set your desired minimum width */
    }
}

.ant-transfer-list-header-selected {
    display: none;
}

.table {
    position: fixed;
    bottom: -260px;
}

.table.open {
    bottom: 0vh;
}

.legend-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 25vw;
    right: 0;
    padding: 10px;
    width: 50vw;
    border-radius: 21px;
}

.legend-section {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 0 10px;
}

.legend-row {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.legend-column {
    margin: 10px; /* Adjust margin as needed */
}

  
.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 8px;
}

.legend-circle {
    border-radius: 50%;
    margin-right: 8px;
}

.legend-triangle {
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    
    border-bottom: 15px solid #A9A9A9;
    margin-right: 8px;
}

.legend-label {
    font-size: 15px;
}

.legend-subtitle {
    display: flex;
    font-size: 15px;
    font-weight: 800;
    width: 100px;
}
  
.suggested-questions {
    padding: 10px 0;
}

.question-section {
    background-color: white;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.question-section h4 {
    margin-top: 0;
    margin-bottom: 10px;
    color: black;
}

.question-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.question-inputs .ant-btn {
    flex: 1;
}

.answer {
    margin-top: 10px;
}

.answer h4 {
    margin-bottom: 5px;
}

.ant-typography {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
}

@media screen and (max-width: 1600px) { /* Adjust this value as needed */
    .settings {
        width: 400px; /* Set a fixed width for smaller screens */
    }
}
