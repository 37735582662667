.navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    /*background-color: #fff; !* Change this as per your color scheme *!*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: for a slight shadow under the nav bar */
    background-color: #4a7298; /* Changed from #FFFFFF to #4a7298 */
}

.navigation-bar .logo a{
    font-size: 30px;
    color: #ffffff; /* Changed from #3D739C to white for better contrast */
    margin-left: 10px;
    align-items: center;
    text-decoration: none;
}

.navigation-bar .nav-links{
    margin-right: 30px;
    font-size: 30px;
}

.navigation-bar .nav-links a {
    text-decoration: none;
    margin-left: 20px;
    color: #ffffff; /* Changed from #3D739C to white for better contrast */
    font-size: 20px;
    font-weight: 800;
     /* Change this as per your color scheme */
    /* Additional styles for the links can go here */
}

.navigation-bar .nav-links a:hover {
    color: #e0e0e0; /* Changed from #555 to a light gray for hover effect */
}