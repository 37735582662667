/* Sticky class for the section titles */
.sticky-title {
    position: sticky;
    top: 0;
    padding: 10px 0;
    /* Removed background-color: #F7F7F7; */
    z-index: 100; /* Ensure it stays on top of other content */
}

.sticky-title h3 {
    margin: 0;
    padding: 10px;
    /* Removed background-color: #F7F7F7; */
    font-family: 'Mundial';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
}

all {
    font-family: 'Mundial' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px !important;
}

.information {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 60px;
    right: 0;
    height: calc(100vh - 60px); /* Full height minus top offset */
    /* Removed background-color: #F7F7F7; */
    border-radius: 0px;
    display: flex;
    flex-direction: column;
}

.information-content {
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* Remove any styles for .related if it's no longer used */

.ant-card {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ant-card-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0;
}

.ant-collapse {
    border: none;
    background-color: transparent;
}

.ant-collapse-content {
    overflow: visible;
}

.panel-header {
    color: #4a7298;
    font-size: 17px;
    font-weight: bold;
    margin: 0;
}

.custom-descriptions {
    background-color: white;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ant-collapse-item {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
}

.ant-collapse-item:last-child {
    border-bottom: none;
}

.ant-collapse-header {
    padding: 12px 16px !important;
}

.ant-collapse-content-box {
    padding: 0 16px 16px !important;
}

.custom-div-edge {
    /*margin: 1px; !* Adjusts the space outside the div *!*/
    padding: 0; /* Adjusts the space inside the div */
    border: none;
    margin-bottom: -13px;
}

.custom-div-url {
    padding: 0; /* Adjusts the space inside the div */
    border: none;
    margin-bottom: -17px;
}

.loading-message, .no-selection-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 16px;
    color: #666;
    text-align: center;
    padding: 20px;
    line-height: 1.5;
    background-color: white;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.loading-message {
    font-style: italic;
}

.no-selection-message {
    font-style: normal;
}