.HomePageContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Changed to 100vh for full viewport height */
    overflow-y: auto;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding-top: 150px; /* Increased padding-top to move content lower */
    padding: 20px;
    justify-content: center;
}

.content img {
    width: 400px;
    margin-bottom: 40px; /* Increased margin-bottom for more space */
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #333;
    font-size: 14px;
}

.footer-img {
    max-width: 200px;
    margin-bottom: 10px;
}

.search-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px; /* Adjust this value to match your search bar width */
    margin: 0 auto;
}

.example-queries {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    margin-top: 40px; /* Increased margin-top for more space */
}

.example-query-button {
    margin-bottom: 10px;
    white-space: normal;
    height: auto;
    padding: 6px 12px;
    text-align: left;
    background-color: #99c7b1;
    color: black;
    border: none;
    transition: background-color 0.3s;
}

.example-query-button:hover {
    background-color: #577265;
}

@media (min-width: 768px) {
    .example-queries {
        flex-direction: row;
        justify-content: space-between;
    }

    .example-query-button {
        width: 32%; /* Adjust as needed to fit three buttons side by side */
    }
}

/* Add styles for the navigation bar */
.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
}

/* Adjust content to account for fixed nav bar */
.content {
    margin-top: 60px; /* Height of the nav bar */
}

.glkb-introduction {
    max-width: 750px;
    margin: 20px auto;
    text-align: center;
    padding: 0 20px;
}

.glkb-introduction p {
    margin-bottom: 10px;
}

.glkb-introduction a {
    color: #007bff;
    text-decoration: none;
}

.glkb-introduction a:hover {
    text-decoration: underline;
}

@media (max-height: 600px) {
    .content {
        padding-top: 60px; /* Adjust this value as needed */
    }
}

